<template>
  <v-main>
    <div class="mx-4">
      <router-view :key="$route.path" />
    </div>
  </v-main>
</template>

<script>
export default {
  name: "DefaultView",
};
</script>
